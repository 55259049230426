import { render, staticRenderFns } from "./bidui-list.vue?vue&type=template&id=21b175da&scoped=true&"
import script from "./bidui-list.vue?vue&type=script&lang=ts&"
export * from "./bidui-list.vue?vue&type=script&lang=ts&"
import style0 from "./bidui-list.vue?vue&type=style&index=0&id=21b175da&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21b175da",
  null
  
)

export default component.exports